/* global axios */
import ApiClient from './ApiClient';

class ConversationApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  getConversations(contactId) {    
    return axios.get(`${this.url}`);
  }

  getLabels(conversationID) {
    return axios.get(`${this.url}/${conversationID}/labels`);
  }

  updateLabels(conversationID, labels) {
    return axios.post(`${this.url}/${conversationID}/labels`, { labels });
  }

  getDetailOfInbox(accountsId, inboxesId) {
    return axios.get(`/api/v1/accounts/${accountsId}/inboxes/${inboxesId}`);
  }

  getConversationsById(accountsId, conversationsId) {
    return axios.get(`/api/v1/accounts/${accountsId}/conversations/${conversationsId}`);
  }

  getAvailableUser(accountsId, inboxesId) {
    return axios.get(`/api/v1/accounts/${accountsId}/inbox_members/${inboxesId}`);
  }

  getMessageByConversation(accountId, conversationId, searchQuery) {
    return axios.get(`/api/v1/accounts/${accountId}/conversations/${conversationId}/messages?search_query=${searchQuery}`);
  }

  notitelegram(data) {
    const token = data.token
    const chat_id = data.chat_id
    const content = data.content
    const time = data.time
    const name = data.name
    const sender_name = data.sender_name
    const date = data.date
    const sender_id = data.sender_id
    const count = data.conut

    return axios.post(`/api/v1/gamefire_telegram/notification15min`, { 
      token: token, 
      chat_id: chat_id, 
      content: content,
      time: time, 
      name: name, 
      sender_name: sender_name, 
      sender_id: sender_id, 
      date: date,
      count: count
    });
  }

  notitelegramOffline(data) {
    const token = data.token
    const chat_id = data.chat_id
    const content = data.content
    const time = data.time
    const name = data.name
    const sender_name = data.sender_name
    const date = data.date
    const sender_id = data.sender_id
    const count = data.conut

    return axios.post(`/api/v1/gamefire_telegram/notificationOffline`, { 
      token: token, 
      chat_id: chat_id, 
      content: content, 
      time: time, 
      name: name, 
      sender_name: sender_name, 
      sender_id: sender_id, 
      date: date,
      count: count
    });
  }
}

export default new ConversationApi();
